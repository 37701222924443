import React, { useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/appContext";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hookLib";
import { User } from "../components/User";
import "./Login.css";

import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
    IResolveParams,
} from 'reactjs-social-login';

import {
    LoginSocialTwitch,
    LoginSocialTiktok
} from '../components/social';

import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
    AppleLoginButton,
} from 'react-social-login-buttons';


import "./Login.css";

export default function Login() {

    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
    });

    const REDIRECT_URI = 'https://supporter.gift/';
    const LOCAL_REDIRECT = 'http://localhost:3000/login';

    const { email, password } = fields;
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState();

    const { setIsAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);


    const onLoginStart = useCallback(() => {
        alert('login start');
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
        alert('logout success');
    }, []);

    const onLogout = useCallback(() => { }, []);

    function validateForm() {
        return true; //email.length > 0 && password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.signIn(email, password);
            setIsAuthenticated(true);

        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            {provider && profile && (
                <User provider={provider} profile={profile} onLogout={onLogout} />
            )}
            <div className={`App ${provider && profile ? 'hide' : ''}`}>
                <div className="login-container row">
                    <div className="social-container column">
                        <LoginSocialFacebook
                            appId={process.env.REACT_APP_FB_APP_ID || '885043399549409'}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            }
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <FacebookLoginButton />
                        </LoginSocialFacebook>

                        <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GG_APP_ID || '252171140139-shstg44ho0ls9ct9hvmtiltjqd8dfrrl.apps.googleusercontent.com'}
                            onLoginStart={onLoginStart}
                            redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                                console.log('provider', provider);
                                console.log('profile', profile);
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <GoogleLoginButton />
                        </LoginSocialGoogle>

                        <LoginSocialTiktok
                            client_id={process.env.REACT_APP_TIKTOK_ID || 'awqjnjw0yydkyoru'}
                            scope={'name email'}
                            redirect_uri={REDIRECT_URI + 'login'}
                            onLoginStart={onLoginStart}
                            onResolve={({ provider, data }) => {
                                console.log('provider', provider);
                                console.log('profile', profile);
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <AppleLoginButton />
                        </LoginSocialTiktok>

                        <LoginSocialInstagram
                            client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || '1071902213685727'}
                            client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || '610cf8088e5dbff5b6ea1fa998612799'}
                            redirect_uri={REDIRECT_URI}
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            onResolve={({ provider, data }) => {
                                console.log('provider', provider);
                                console.log('profile', profile);
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <InstagramLoginButton />
                        </LoginSocialInstagram>

                        <LoginSocialTwitch
                            client_id={process.env.REACT_APP_GITHUB_APP_ID || '6x9nog978crv9c4ypeouiyjupwdtz4'}
                            client_secret={process.env.REACT_APP_GITHUB_APP_SECRET || 'gqg10r0vm6kvdhgpjlt45v0lydhtz0'}
                            redirect_uri={LOCAL_REDIRECT}
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            onResolve={({ provider, data }) => {
                                console.log('provider', provider);
                                console.log('profile', profile);
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <GithubLoginButton />
                        </LoginSocialTwitch>

                        <LoginSocialTwitter
                            client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || 'V1doSjFQZ2IyaGtPNVJlVEZKb2k6MTpjaQ'}
                            // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                            redirect_uri={LOCAL_REDIRECT}
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            onResolve={({ provider, data }) => {
                                console.log('provider', provider);
                                console.log('profile', profile);
                                setProvider(provider);
                                setProfile(data);
                            }}
                            onReject={(err) => {
                                console.log(err);
                            }}
                        >
                            <TwitterLoginButton />
                        </LoginSocialTwitter>
                    </div>
                    <div className="form-container column">
                        <h1 className="">Social Login</h1>
                        <br/>
                        <div className="Login">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group size="lg" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        autoFocus
                                        type="email"
                                        value={email}
                                        onChange={handleFieldChange}
                                    />
                                </Form.Group>
                                <Form.Group size="lg" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        onChange={handleFieldChange}
                                    />
                                </Form.Group>
                                <div>
                                    <a className="forgot-login" href="/forgot">Forgot Password?</a>
                                </div>
                                <br/>
                                <LoaderButton
                                    block="true"
                                    size="lg"
                                    type="submit"
                                    isLoading={isLoading}
                                    disabled={!validateForm()}
                                >
                                    Login
                                </LoaderButton>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}