/* eslint-disable camelcase */
/**
 *
 * LoginSocialTiktok
 *
 */
import { PASS_CORS_KEY } from './helper';
import React, { memo, useCallback, useEffect } from 'react';

const TIKTOK_URL = 'https://www.tiktok.com';
const TIKTOK_API_URL = '';
const PREVENT_CORS_URL = 'https://cors.bridged.cc';

const csrfState = Math.random().toString(36).substring(2);


export const LoginSocialTiktok = ({
    state = 'tiktok_' + csrfState,
    scope = 'user.info.basic',
    client_id,
    client_secret,
    className = '',
    redirect_uri,
    allow_signup = false,
    isOnlyGetToken = false,
    isOnlyGetCode = false,
    children,
    onReject,
    onResolve,
    onLoginStart
}) => {
    useEffect(() => {
        const url = window.location.href;
        const popupWindowURL = new URL(url);
        const code = popupWindowURL.searchParams.get('code');
        const state = popupWindowURL.searchParams.get('state');
        if (state?.includes('tiktok') && code) {
            localStorage.setItem('tiktok', code);
            window.close();
        }
    }, []);

    const getProfile = useCallback(() => {

    }, []);

    const getAccessToken = useCallback(() => {
        
    }, []);

    const handlePostMessage = useCallback(async ({ type, code, provider }) =>
        type === 'code' && provider === 'tiktok' && code && getAccessToken(code),
        [getAccessToken]);

    const onChangeLocalStorage = useCallback(() => {
        window.removeEventListener('storage', onChangeLocalStorage, false);
        const code = localStorage.getItem('tiktok');
        if (code) {
            handlePostMessage({ provider: 'tiktok', type: 'code', code });
            localStorage.removeItem('tiktok');
        }
    }, [handlePostMessage]);

    const onLogin = useCallback(() => {
        onLoginStart && onLoginStart();
        window.addEventListener('storage', onChangeLocalStorage, false);
        const oauthUrl = `${TIKTOK_URL}/auth/authorize/?client_key=${client_id}&scope=user.info.basic,video.list&response_type=code&redirect_uri=${redirect_uri}&state=${state}`;
        console.log(oauthUrl)
        const width = 450;
        const height = 730;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
            oauthUrl,
            'Tiktok',
            'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
            width +
            ', height=' +
            height +
            ', top=' +
            top +
            ', left=' +
            left,
        );
    }, [scope,
        state,
        client_id,
        redirect_uri,
        allow_signup,
        onLoginStart,
        onChangeLocalStorage,]);

    return (
        <div className={className} onClick={onLogin}>
            {children}
        </div>
    );
}

export default memo(LoginSocialTiktok);