import react, { useState, useEffect } from 'react';
import { useAppContext } from '../lib/appContext';
import { SupporterRank } from '../components/SupporterRank';
import { onError } from "../lib/errorLib";
import "./Dashboard.css";
import { SocialAccount } from '../components/SocialAccounts';

export default ({ navigation }) => {
    //const [gifts, setGifts] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();

    console.log('Dashboard Effect')
  }, []);

    
  const loadingRender = () => (<h4>Loading</h4>);

  const loadedRender = () => (<>
    <SupporterRank />
    <SocialAccount />
  </>
  );

    return (
        <div>
            <h2>Dashboard</h2>
            { 
                isLoading ? 
                loadingRender() :
                loadedRender()
            }
        </div>
    );
}